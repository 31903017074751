<template>
    <modal ref="modal" :show="isEditingOrderItem" @onClose="close" :saveDisabled="false">
        <template #title>
            <span v-if="$store.state.editingOrderItem && $store.state.editingOrderItem.id">Product bewerken</span>
            <span v-else>Product toevoegen</span>
        </template>

        <div v-if="$store.state.editingOrderItem && $store.state.editingOrderItem.id">
            <order-item-teaser :with-customer="true" :order="order" :order-item="Object.assign({}, $store.state.editingOrderItem)"></order-item-teaser>
        </div>

        <div v-if="!loading">
            <div v-if="linkedOrders.length" class="alert alert--orange">
                <i class="fal fa-info-circle"></i> Let op, andere producten op dit adres
            </div>
            <div class="alert alert--orange" v-if="isEditingOrderItem && $store.state.editingOrderItem && $store.state.editingOrderItem.id && $store.state.editingOrderItem.custom_price_incl !== null">
                Let op, dit product heeft een aangepaste prijs
            </div>

            <div class="box-card box-card--single">
                <div class="box-card-content">
                    <h4>Leverinformatie</h4>

                    <form-control class="form-item--compact" name="delivery_address_id" label="Afleveradres"
                                  :edit="true">
                        <template v-slot:input>
                            <div class="form-item-select form-item-select--withAdd">
                                <dropdown-select v-if="order.customer" class="form-view"
                                                 :initial-selected="selectedAddress"
                                                 @onSelect="setAddress"
                                                 :preload="true"
                                                 :itemCallback="(item) => ({
                                                    id: item.id,
                                                    value: item.name,
                                                 })"
                                                 :url="'/company/api/sales/customers/' + order.customer.id + '/addresses?type=address'">
                                </dropdown-select>
                                <div @click="onAddAddressClick('delivery')" class="form-item-select-add">
                                    <i class="far fa-plus-circle"></i>
                                </div>
                            </div>
                        </template>
                    </form-control>

                    <div class="form-row">

                        <form-control class="col form-item--compact"
                                      name="preferred_delivery_at"
                                      :edit="$store.getters.isEditable"
                                      label="Voorkeur leverdatum *"
                                      :value="order.preferred_delivery_at_formatted ? order.preferred_delivery_at_formatted  :  'Onbekend'">
                            <template v-slot:input>
                                <input v-model="deliveryAt"
                                       class="form-control js-datepicker"
                                       type="text">
                            </template>
                        </form-control>

                        <div class="col" :style="order.supplier ? 'cursor: pointer;' : 'cursor: not-allowed'">

                            <form-control class="form-item--compact"
                                          name="preferred_pickup_at"
                                          :edit="$store.getters.isEditable"
                                          label="Voorkeur ophaaldatum"
                                          :value="order.preferred_pickup_at_formatted ? order.preferred_pickup_at_formatted :  'Onbekend'">
                                <template v-slot:input>
                                    <input class="form-control js-datepicker" type="text" v-model="pickupAt">
                                </template>

                            </form-control>
                            <div v-if="changedPickupAt && order.supplier">
                                <div class="form-item form-item--checkbox">
                                    <input id="send-pickup-request" v-model="sendPickupRequest" :disabled="!pickupAt"
                                           type="checkbox">
                                    <label for="send-pickup-request" class="control-label">Ophaalaanvraag versturen naar
                                        de leverancier</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!order.supplier && hasConditionalRequestForDeliveryDate"
                         class="alert alert--orange">
                        Er staat een geaccepteerde conditionele aanvraag voor deze datum.
                    </div>

                    <div class="form-row">
                        <form-control class="col form-item--compact"
                                      name="delivery_preference_id"
                                      :edit="$store.getters.isEditable"
                                      label="Voorkeur dagdeel leveren"
                                      :value="order.delivery_preference_formatted ? order.delivery_preference_formatted :  'Onbekend'">
                            <template v-slot:input>
                                <select class="form-control" type="date" v-model="deliveryPreferenceId">
                                    <option :value="option.id" v-for="option in $store.state.deliveryPreferences" v-text="option.name">
                                    </option>
                                </select>
                            </template>
                        </form-control>

                        <div class="col">
                            <form-control v-if="pickupAt" class="form-item--compact"
                                          name="pickup_preference_id"
                                          :edit="$store.getters.isEditable"
                                          label="Voorkeur dagdeel ophalen"
                                          :value="order.pickup_preference_formatted ? order.pickup_preference_formatted :  'Onbekend'">
                                <template v-slot:input>
                                    <select class="form-control"
                                            type="date"
                                            v-model="pickupPreferenceId">
                                        <option :value="option.id"
                                                v-for="option in $store.state.pickupPreferences"
                                                v-text="option.name">
                                        </option>
                                    </select>
                                </template>
                            </form-control>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-item form-item--checkbox">
                            <input v-model="pickupSameDay" :disabled="order.preferred_pickup_at" type="checkbox"
                                   id="same-day">
                            <label for="same-day" class="control-label">Zelfde dag ophalen</label>
                        </div>
                    </div>

                    <div class="form-row">

                        <form-control class="col form-item--compact"
                                      name="phone_number_id"
                                      label="Bel naar telefoonnummer 30 min. voor leveren *"
                                      :edit="$store.getters.isEditable">
                            <template #input>
                                <div class="form-item-select form-item-select--withAdd">
                                    <dropdown-select v-if="order.customer" class="form-view" :initial-selected="callBeforePhoneNumber"
                                                     @onSelect="setPhoneNumber"
                                                     :preload="true"
                                                     :itemCallback="(item) => ({
                                                     id: item.id,
                                                     value: item.display,
                                                 })"
                                                     :url="'/company/api/sales/customers/' + order.customer.id + '/phone-numbers'">
                                    </dropdown-select>

                                    <div class="form-item-select-add" @click="onAddPhoneNumberClick()">
                                        <i class="far fa-plus-circle"></i>
                                    </div>
                                </div>
                            </template>
                        </form-control>
                        <div class="col">
                            <form-control class="form-item--compact" :edit="true" name="payment_method_id"
                                          label="Betaalmethode *"
                                          :value="selectedPaymentMethodId ? getPaymentMethod().name : 'Onbekend'">
                                <template v-slot:input>
                                    <select :disabled="$store.state.order.paid_invoice" class="form-control"
                                            v-model="selectedPaymentMethodId">
                                        <option :value="option.id" v-text="option.name"
                                                :disabled="option.enabled === false"
                                                v-for="option in $store.state.paymentMethods">{{ option }}
                                        </option>
                                    </select>
                                </template>
                            </form-control>

                            <div class="alert alert--warning mb-3"
                                 v-if="selectedPaymentMethodId && getPaymentMethod().direct_debit && !$store.state.order.customer.iban_account_number">
                                Geen IBAN bekend bij deze klant.
                            </div>

                            <div class="alert alert-success mb-3"
                                 v-if="selectedPaymentMethodId && getPaymentMethod().direct_debit && $store.state.order.customer.iban_account_number">
                                IBAN bekend: {{ $store.state.order.customer.iban_account_number }}
                            </div>
                        </div>

                    </div>

                    <div class="form-row">
                        <div class="col">
                            <div class="form-item form-item--compact">
                                <label class="control-label">Opmerkingen leveren leverancier</label>
                                <textarea class="form-control" v-model="delivery_instructions"></textarea>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-item form-item--compact">
                                <label class="control-label">Opmerkingen ophalen leverancier</label>
                                <textarea class="form-control" v-model="pickup_instructions"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <div class="form-item form-item--compact">
                                <label class="control-label">
                                    Referentie
                                    <span v-if="order.customer && order.customer.project_reference_required">(verplicht)</span>
                                </label>
                                <input class="form-control" type="text" v-model="projectReference">
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-item form-item--compact">
                                <label class="control-label">Interne opmerkingen</label>
                                <textarea class="form-control" v-model="notes"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-card box-card--single">
                <div class="box-card-content">
                    <h4>Product</h4>
                    <div class="alert alert--danger" v-if="!productIsEditable">
                        Dit product is niet te wijzigen omdat er een inkoopfactuur gekoppeld is in Scan & Herken.
                    </div>
                    <div class="product-image-container product-image-box--bottom">
                        <div v-if="selectedProduct.id" class="product-image-box">
                            <div class="product-image" :style="'background-image: url(' + selectedProduct.image_url + ')'"></div>
                        </div>
                        <div class="form-item form-item--compact form-item--compactInline">
                            <label class="control-label">Product</label>
                            <select :disabled="!productIsEditable" :value="productId" class="form-control" @change="(event) => setProduct(event.target.value)">
                                <option value="">Selecteer een product</option>
                                <option :value="product.id" v-text="getProductName(product)" v-for="product in products"></option>
                            </select>
                        </div>
                    </div>

                    <div v-if="selectedProduct.id">
                        <div v-for="attribute in selectedProduct.price.price_attributes">
                            <h5>{{ attribute.name }}
                                <span v-if="attributeIsRequired(attribute)">*</span><span v-if="attributeIsLocked(attribute)"><i class="fa fa-ban" data-toggle="tooltip" title="Dit attribuut is geblokkeerd voor bewerken omdat de levering al plaats heeft gevonden."></i></span>
                            </h5>
                            <table class="table table--noHeader">
                                <tbody>
                                <tr v-for="option in attribute.options" :class="attributeIsLocked(attribute) || option.disabled_reason ? 'cursor-not-allowed' : 'pointer'" @click="selectOption(attribute, option)">
                                    <td>
                                        <input type="radio" :disabled="attributeIsLocked(attribute) || !productIsEditable || option.disabled_reason" :checked="isChecked(attribute, option)">
                                        <span v-if="option.disabled_reason"><i class="fa fa-ban" data-toggle="tooltip" :title="option.disabled_reason"></i></span>
                                    </td>
                                    <td>
                                        {{ option.name }}
                                    </td>
                                    <td v-if="isInvoicedOrderItem && option">
                                        <span v-if="getPriceDifference(option) > 0" class="text-success">+ {{ Math.abs(getPriceDifference(option)) | format_money }}</span>
                                        <span v-if="getPriceDifference(option) < 0" class="text-danger">- {{ Math.abs(getPriceDifference(option)) | format_money }}</span>
                                        <span v-if="getPriceDifference(option) === 0">Geen verschil</span>
                                    </td>
                                    <td v-if="!isInvoicedOrderItem">
                                        {{ option.price_incl * quantity | format_money }}
                                        <span class="text-danger" v-if="option.discounted_price_incl">
                                        {{ option.discounted_price_incl * quantity | format_money }}
                                    </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <div class="box-card box-card--single">
                <div class="box-card-content">
                    <h4>Overzicht</h4>
                    <div class="form-row">
                        <div class="col">

                            <div class="form-item">
                                <label data-toggle="tooltip" title="Prijs Incl. BTW" class="control-label">Totaalbedrag <i
                                    class="fa fa-info-circle"></i></label>
                                <input :disabled="!productIsEditable" class="form-control" type="text" v-model="customPriceIncl" @change="handleCustomPriceInclBlur">
                                <span v-if="hasVariationDiscount" class="text-muted">Automatisch ingevuld op basis van prijsvariaties</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-item">
                                <label class="control-label">Korting</label>
                                <input type="text" disabled class="form-control" :value="totalDiscountLabel()"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-item" v-if="customPriceReason">
                        <label class="control-label">Reden</label>
                        <textarea disabled="disabled" class="form-control">{{ customPriceReason }}</textarea>
                    </div>
                    <div class="form-item" v-if="customPriceIncl">
                        <label class="control-label">Reden</label>
                        <p v-if="$store.state.customPriceTypes.length === 0"><a href="/custom-price-types">Geen redenen aangemaakt. Klik hier om deze aan te maken.</a></p>
                        <div class="form-check" v-for="customPriceType in $store.state.customPriceTypes">
                            <input class="form-check-input" type="radio" :id="'cp-reason-' + customPriceType.id"
                                   v-model="customPriceTypeId" :value="customPriceType.id">
                            <label class="form-check-label" :for="'cp-reason-' + customPriceType.id">
                                {{ customPriceType.name }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="alert alert--warning alert--large"
                 v-if="changedSomethingThatHasConsequences && isInvoicedOrderItem">
                <p v-if="changedOption && !changedProduct">
                    <strong>
                        Productopties aangepast!
                    </strong>
                </p>
                <p>
                    <strong>
                        Let op, door deze keuze worden de volgende zaken in gang gezet:
                    </strong>
                </p>
                <ul>
                    <li v-if="(changedProduct || changedOption) && selectedOptions[0] && getPriceDifference(selectedOptions[0]) > 0">
                        Klant moet {{ getPriceDifference(selectedOptions[0]) | format_money }} extra betalen
                    </li>
                    <li v-if="(changedProduct || changedOption) && getPriceDifference(selectedOptions[0]) < 0">
                         <span v-if="oldOrderItem.is_paid">
                            Klant krijgt {{ Math.abs(getPriceDifference(selectedOptions[0])) | format_money }} terug
                        </span>
                        <span v-else>
                            Klant hoeft {{ Math.abs(getPriceDifference(selectedOptions[0])) | format_money }} minder te betalen
                        </span>
                    </li>
                    <li v-if="customPriceIncl !== 0 && customPriceIncl">
                        De totaalprijs incl. btw voor de klant wordt {{ this.customPriceIncl | format_money }}
                    </li>
                    <li v-if="changedProduct">
                        Product wordt aangepast
                    </li>
                    <li v-if="changedPaymentMethod">
                        Mail klant: Betaalmethode gewijzigd
                    </li>
                    <li v-if="sendPickupRequest && order.supplier && pickupAt">
                        Ophaalaanvraag naar leverancier
                    </li>
                    <li v-if="changedOption">
                        Factuur naar de klant
                    </li>
                    <li v-if="changedOption || changedProduct">
                        Exact factuur
                    </li>
                    <li v-if="changedDeliveryAt && !this.order.supplier">
                        Alle verzoeken worden ingetrokken en er worden nieuwe verzoeken gegenereerd
                    </li>
                    <li v-if="changedDeliveryAt && this.order.supplier">
                        De leverancier krijgt een mutatie met de nieuwe leverdatum
                    </li>
                    <li v-if="changedDeliveryAt">
                        Mail klant: leverdatum gewijzigd
                    </li>
                    <li v-if="changedPickupAt">
                        Mail leverancier: ophaaldatum gewijzigd
                    </li>
                    <li v-if="changedDeliveryAddress">
                        Mail klant: afleveradres gewijzigd
                    </li>
                    <li v-if="changedInstructions">
                        Leverancier krijgt mail: instructies gewijzigd
                    </li>

                </ul>
            </div>
        </div>

        <div v-else style="min-height: 600px" class="w-100 d-flex justify-content-center align-items-center">
            <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
        </div>

        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    Annuleren
                </button>
                <loading-button :loading="saving" :disabled="saveDisabled" @click.native="save">
                    <span v-if="isInvoicedOrderItem">Wijzigingen doorvoeren</span>
                    <span v-if="!isInvoicedOrderItem">Item toevoegen</span>
                </loading-button>
            </div>
        </template>

    </modal>
</template>

<script>

import Modal from "./Modal";
import OrderItemTeaser from "./OrderItemTeaser";
import FormControl from "./FormControl";
import orderItemMixin from "../../../mixins/orderItemMixin";

export default {
    name: "OrderItemForm",
    components: {
        FormControl,
        OrderItemTeaser,
        Modal,
    },

    mixins: [orderItemMixin],

    data: () => ({
        productId: null,
        products: [],
        selectedProduct: {
            id: null,
            price: { price_attributes: [] }
        },
        selectedOptions: [],
        callBeforePhoneNumber: null,
        selectedAttributes: [],
        quantity: 1,
        projectReference: '',
        notes: '',
        pickupSameDay: false,
        selectedAddress: null,
        loading: false,
        pickupAt: null,
        pickupPreferenceId: null,
        deliveryAt: null,
        deliveryPreferenceId: null,
        oldOrderItem: null,
        delivery_instructions: null,
        tracking_url: null,
        pickup_instructions: null,
        sendPickupRequest: false,
        selectedPaymentMethodId: null,
        customPriceIncl: null,
        customPriceReason: null,
        customPriceTypeId: null,
        variations: [],
        hasVariationDiscount: false,
        saving: false,
    }),
    mounted() {
        window.OrderItemAddressModal = window.OrderItemAddressModal || AdminModal.Instance();
        window.PhoneNumberModal = AdminModal.Instance();

        window.OrderItemAddressModal.On('submit', (e) => {
            this.creatingAddress = false;
            console.log('submit');
            $(this.$refs.modal.$refs.el).modal('show');
            $(this.$refs.modal.$refs.el).data('clear', true);
            this.setAddress(e.item);
        });

    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip();
    },
    computed: {
        linkedOrders() {
            return this.$store.state.linkedOrders;
        },
        order() {
            return this.$store.state.order;
        },
        customPriceTypes() {
            return this.$store.state.customPriceTypes;
        },
        saveDisabled() {
            if (this.deliveryAt === null) {
                return true;
            }

            if (this.selectedProduct.id === null) {
                return true;
            }

            if (this.order.customer && this.order.customer.project_reference_required && !this.projectReference) {
                return true;
            }

            if (this.selectedPaymentMethodId == null) {
                return true;
            }

            if (!this.callBeforePhoneNumber || !this.callBeforePhoneNumber.id) {
                return true;
            }

            if (this.changedPhoneNumber) {
                return false;
            }

            if (this.customPriceIncl !== 0 && this.customPriceIncl) {
                return false;
            }

            return !this.hasRequiredAttributesSelected() || ((this.isInvoicedOrderItem && !this.changedSomethingThatHasConsequences) && !this.changedGeneralInfo);
        },
        isEditingOrderItem() {
            return this.$store.state.isEditingOrderItem;
        },
        isInvoicedOrderItem() {
            return this.$store.state.editingOrderItem && this.$store.state.editingOrderItem.id && this.$store.state.editingOrderItem.invoiced_at !== null
        },
        changedProduct() {
            if (!this.oldOrderItem) {
                return false;
            }
            return this.oldOrderItem.product.id !== this.productId;
        },
        changedDeliveryAddress() {
            if (!this.oldOrderItem) {
                return true;
            }
            if (!this.selectedAddress) {
                return false;
            }
            return this.selectedAddress.id !== this.order.delivery_address.id;
        },
        selectedPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.selectedPaymentMethodId);
        },
        changedPaymentMethod() {
            return this.order.payment_method_id !== this.selectedPaymentMethodId
        },
        changedOption() {
            if (!this.oldOrderItem) {
                return false;
            }

            if (!this.selectedAttributes.length) {
                return false;
            }

            if (this.selectedAttributes.length !== this.oldOrderItem.attributes.length) {
                return true;
            }

            for (const attributesKey in this.oldOrderItem.attributes) {
                const orderItemProductAttribute = this.oldOrderItem.attributes[attributesKey];
                const index = this.selectedAttributes.findIndex(a => a.id === orderItemProductAttribute.price_product_attribute_id);
                if (index === -1) {
                    return false;
                }
                const productAttribute = this.selectedAttributes[index];
                if (orderItemProductAttribute.price_product_attribute_option_id !== productAttribute.option_id) {
                    return true;
                }
            }
            return false;
        },
        hasConditionalRequestForDeliveryDate() {
            return this.order.supplier_requests.findIndex(s => {
                if (s.conditional === false) {
                    return false;
                }

                return new Date(this.deliveryAt).toISOString() === new Date(s.delivery_at).toISOString();
            }) !== -1;
        },
        changedDeliveryAt() {
            return this.order.delivery_at !== this.deliveryAt;
        },
        changedPickupAt() {
            return this.order.preferred_pickup_at !== this.pickupAt;
        },
        changedDeliveryInfo() {
            let order = this.order;
            if (order.preferred_delivery_at !== this.deliveryAt) {
                return true;
            }
            if (this.changedPickupAt) {
                return true;
            }
            return false;
        },
        changedInstructions() {
            if (this.order.pickup_instructions !== this.pickup_instructions) {
                return true;
            }

            return this.order.delivery_instructions !== this.delivery_instructions;
        },
        changedPhoneNumber() {
            return this.callBeforePhoneNumber && this.callBeforePhoneNumber.id !== this.order.call_before_phone_number_id;
        },
        changedGeneralInfo() {
            let order = this.order;

            if (order.project_reference !== this.projectReference) {
                return true;
            }
            if (order.call_before_phone_number && order.call_before_phone_number.id !== this.callBeforePhoneNumber.id) {
                return true;
            }
            if (order.delivery_preference_id !== this.deliveryPreferenceId) {
                return true;
            }
            if (order.pickup_preference_id !== this.pickupPreferenceId) {
                return true;
            }
            if (order.notes !== this.notes) {
                return true;
            }
            return order.payment_method_id !== this.selectedPaymentMethodId;
        },
        changedSomethingThatHasConsequences() {
            return (this.customPriceIncl !== 0 && this.customPriceIncl) || this.changedProduct || this.changedOption || this.changedDeliveryInfo || this.changedDeliveryAddress || this.changedInstructions || this.changedPaymentMethod;
        },
    },

    methods: {
        handleCustomPriceInclBlur(){
            console.log(this.customPriceIncl);
            this.customPriceIncl = parseAllFloat(this.customPriceIncl);
        },
        onAddAddressClick() {
            this.creatingAddress = true;
            $(this.$refs.modal.$refs.el).data('clear', false);
            $(this.$refs.modal.$refs.el).modal('hide');
            window.OrderItemAddressModal.Load(`/master-data/addresses/create?customer_id=${this.order.customer.id}&type=address`)
        },
        show() {
            this.showModal = true;
        },
        hide() {
            this.showModal = false;
        },
        setAddress(address) {
            this.selectedAddress = address;
        },
        loadProducts() {
            this.loading = true;
            return axios.post('/company/api/sales/order/products', {
                webshop_id: this.order.webshop.id,
                customer_id: this.order.customer.id,
                order_id: this.order.id,
                preferred_delivery_at: this.order.id ? this.order.created_at : (new Date()).toISOString(),
            }).then(res => {
                this.products = res.data.data;
                this.loading = false;
                return res;
            })
        },
        attributeIsRequired(attribute) {
            const category = this.selectedProduct.product_category;
            const index = category.product_category_attributes.findIndex(a => {
                return a.id === attribute.product_category_attribute_id && a.is_required
            });
            return index !== -1;
        },
        isChecked(attribute, option) {
            const selectedAttribute = this.getSelectedAttribute(attribute)
            if (!selectedAttribute) {
                return false;
            }

            if (selectedAttribute.option_id === option.id) {
                return true;
            }

            return false;
        },
        getSelectedAttribute(priceAttribute) {
            return this.selectedAttributes.find(a => a.id === priceAttribute.id)
        },
        close() {
            if (this.creatingAddress){
                return;
            }
            this.productId = null;
            this.products = [];
            this.selectedProduct = {
                id: null,
                price: { price_attributes: [] }
            };
            this.selectedOptions = [];
            this.quantity = 1;
            this.loading = false;
            this.$store.state.isEditingOrderItem = false;
            this.$store.state.editingOrderItem = null;
            this.selectedPaymentMethodId = null;
            this.customPriceIncl = null;
        },
        saveDeliveryInfo() {
            if (!this.changedDeliveryInfo && !this.changedGeneralInfo && !this.changedInstructions && !(this.selectedAddress && this.order.delivery_address_id !== this.selectedAddress.id)) {
                return true;
            }

            return this.$store.dispatch('setDeliveryInfo', {
                preferred_pickup_at: this.pickupAt,
                preferred_pickup_at_formatted: this.pickupAt,
                preferred_delivery_at_formatted: this.deliveryAt,
                preferred_delivery_at: this.deliveryAt,
                delivery_preference_id: this.deliveryPreferenceId,
                pickup_preference_id: this.pickupPreferenceId,
                project_reference: this.projectReference,
                delivery_instructions: this.delivery_instructions,
                pickup_instructions: this.pickup_instructions,
                notes: this.notes,
                call_before_phone_number_id: this.callBeforePhoneNumber ? this.callBeforePhoneNumber.id : null,
                delivery_address: this.selectedAddress,
                delivery_address_id: this.selectedAddress ? this.selectedAddress.id : null,
                payment_method_id: this.selectedPaymentMethodId,
                send_pickup_request: this.sendPickupRequest,
            }).then(() => {
                if (this.sendPickupRequest && this.order.supplier && this.order.preferred_pickup_at) {
                    return this.$store.dispatch('sendPickupRequest', {
                        date: this.order.preferred_pickup_at,
                    }).then(() => {
                        return true;
                    });
                }
                return true;
            });
        },
        save() {
            this.saving = true;
            console.log('set true');
            this.store()
                .then(() => {
                    this.close();
                })
                .finally(() => {
                    console.log('set false');
                    this.saving = false;
                })
        },
        async store() {
            let savedDeliveryInfo = await this.saveDeliveryInfo();

            if (savedDeliveryInfo !== true) {
                return;
            }

            if (!this.selectedAttributes.length) {
                toastr.error('Kon het product niet aanpassen! Gelieven de pagina te verversen.', 'danger');
                return;
            }

            let track_id = null;

            if (this.$store.state.order.order_items[0]) {
                track_id = this.$store.state.order.order_items[0].track_id;
            }

            if (this.isInvoicedOrderItem) {
                return this.$store.dispatch('saveInvoicedOrderItem', {
                    order_item_id: this.$store.state.editingOrderItem.id,
                    product_id: this.selectedProduct.id,
                    attributes: this.selectedAttributes,
                    quantity: this.quantity,
                    changed_product: this.changedProduct,
                    changed_option: this.changedOption,
                    custom_price_incl: this.customPriceIncl,
                    custom_price_reason: this.customPriceReason,
                    custom_price_type_id: this.customPriceTypeId,
                    has_variation_discount: true,
                });
            }

            if (!this.selectedProduct) {
                console.log(Object.assign({}, this.selectedProduct));
                console.log(Object.assign({}, this.$store.state), 'CURRENT STATE');
                let message = 'Geen product. Gelieve direct Fruitcake te bellen en de pagina niet te verversen aub!';
                toastr.error(message);
                alert(message);
                return;
            }

            return this.$store.dispatch('saveOrderItem', Object.assign({}, {
                attributes: this.selectedAttributes,
                product: this.selectedProduct,
                quantity: this.quantity,
                track_id: track_id,
                order_item_id: this.$store.state.editingOrderItem ? this.$store.state.editingOrderItem.id : null,
                custom_price_incl: this.customPriceIncl,
                custom_price_reason: this.customPriceReason,
                custom_price_type_id: this.customPriceTypeId,
                has_variation_discount: true,
            }));
        },
        getPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.selectedPaymentMethodId);
        },
    },
    watch: {
        selectedAttributes: async function (val) {
            this.hasVariationDiscount = false;
            if (!this.hasRequiredAttributesSelected()) {
                return;
            }
            const variationData = await this.$store.dispatch('getPriceVariations', { attributes: val, product_id: this.selectedProduct.id, address_id: this.selectedAddress.id });
            const variationPrice = Array.isArray(variationData) ? variationData.reduce((val, item) => val + item.price_incl, 0) : this.getAttributeTotalPrice();

            if (this.order.id) {
                return; //dont change price for existing orders.
            }

            this.variations = variationData;
            if (this.getAttributeTotalPrice() !== variationPrice) {
                this.customPriceIncl = variationPrice.toString();
                this.hasVariationDiscount = true
            }
        },
        pickupSameDay: function (val) {
            if (val) {
                this.pickupAt = this.deliveryAt;
            }
        },
        deliveryAt: function (val) {
            this.pickupSameDay = val === this.pickupAt;

            if (this.pickupSameDay) {
                this.pickupAt = val;
            }
        },
        pickupAt: function (val, old) {
            this.pickupSameDay = val === this.deliveryAt;

            this.sendPickupRequest = false;
            if (val && !old && this.order.supplier && this.pickupAt !== this.order.preferred_pickup_at) {
                this.sendPickupRequest = true;
                return;
            }
        },
        'isEditingOrderItem': function (val) {
            if (!val) {
                return;
            }
            this.loadProducts().then(() => {
                if (this.$store.state.editingOrderItem) {
                    this.oldOrderItem = Object.assign({}, this.$store.state.editingOrderItem);
                    this.setProduct(this.$store.state.editingOrderItem.product.id);
                    this.quantity = this.$store.state.editingOrderItem.quantity;
                    this.hasVariationDiscount = this.oldOrderItem.has_variation_discount;

                    this.customPriceIncl = this.$store.state.editingOrderItem.custom_price_incl;
                    this.customPriceReason = this.$store.state.editingOrderItem.custom_price_reason;
                    this.customPriceTypeId = this.$store.state.editingOrderItem.custom_price_type_id;
                }
                this.setPhoneNumber(this.order.call_before_phone_number)
                this.selectedAddress = this.order.delivery_address;
                this.pickupAt = this.order.preferred_pickup_at;
                this.deliveryAt = this.order.preferred_delivery_at;
                this.deliveryPreferenceId = this.order.delivery_preference_id;
                this.pickupPreferenceId = this.order.pickup_preference_id;
                this.projectReference = this.order.project_reference;
                this.delivery_instructions = this.order.delivery_instructions;
                this.pickup_instructions = this.order.pickup_instructions;
                this.notes = this.order.notes;
                this.selectedPaymentMethodId = this.order.payment_method_id;
            });
        },
        selectedAddress: async function (val) {
            if (val) {
                this.$store.dispatch('loadLinkedOrders', val);

                if (!this.hasRequiredAttributesSelected() || !this.selectedProduct.id || !this.selectedAttributes.length) {
                    return;
                }

                const variationData = await this.$store.dispatch('getPriceVariations', {
                    attributes: this.selectedAttributes,
                    product_id: this.selectedProduct.id,
                    address_id: this.selectedAddress.id
                });
                const variationPrice = variationData.reduce((val, item) => val + item.price_incl, 0)

                if (this.order.id) {
                    return; //dont change price for existing orders.
                }

                this.hasVariationDiscount = false;
                this.customPriceIncl = null;
                this.customPriceReason = null;
                this.customPriceTypeId = null;

                this.variations = variationData;
                if (this.getAttributeTotalPrice() !== variationPrice) {
                    this.customPriceIncl = variationPrice.toString();
                    this.hasVariationDiscount = true
                }
            }
        },
    }
}
</script>
